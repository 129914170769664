.App {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .App-logo {
  width: 30vmin;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(77, 77, 77);
}

.App-header {
  margin: 1rem;
  padding: 1rem 1rem 0.25rem 1rem;
  background-color: rgb(255, 255, 255);
  border: solid rgb(21, 21, 21) 1px;
  border-radius: 0.5rem;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  filter: drop-shadow(0 0 0.5rem #232323);
  will-change: filter;
  transition: filter 0.2s ease-in-out;
  font-size: 20px;
}

.App-header:hover {
  filter: drop-shadow(0 0 0.5rem #fb6161);
  will-change: filter;
  transition: filter 0.2s ease-in-out;
}

.btn-snapshot-tooltip-hide {
  border: none;
  outline: none;
  background: transparent;
}

.btn-snapshot-tooltip-show {
  --arrow-size: 8px;
  color: rgb(36, 24, 96);
  border: none;
  outline: none;
  background: transparent;
  position: relative;
}

.btn-snapshot-tooltip-show::before,
::after {
  position: absolute;
  left: -0.25rem;
  top: 50%;
  transform: translateX(var(--translate-x, 0)) translateY(-50%);
}

.btn-snapshot-tooltip-show::before {
  --translate-x: calc(-100%);
  content: attr(data-tooltip);
  color: white;
  border-radius: 0.3rem;
  text-align: center;
  padding: 0.5rem;
  width: max-content;
  max-width: 600%;
  max-height: 300%;
  background: #333;
}

.btn-snapshot-tooltip-show::after {
  --translate-x: -1px;
  content: "";
  border: var(--arrow-size) solid transparent;
  border-left-color: #333;
}

.input-div {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.App-link {
  color: #61dafb;
}

.page-content {
  width: 100%;
  max-width: 800px;
  filter: drop-shadow(0 0 0.5rem #232323);
}

.nav-header {
  box-sizing: border-box;
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem 0 0 0;
  border: solid rgb(21, 21, 21);
  border-width: 1px 1px 0 1px;
  border-radius: 0.5rem 0.5rem 0 0;
  justify-self: flex-start;
  margin-top: 1rem;
}
.generic {
  font-size: 20px !important;
  color: #61dafb;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(255, 255, 255);
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  border: solid;
  border-color: transparent;
  border-width: 0 0 2px 0;
}

.generic-infocus {
  font-size: 20px !important;
  color: #61dafb;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(255, 255, 255);
  font-size: calc(10px + 2vmin);
  border: solid rgb(21, 21, 21);
  border-width: 0 0 2px 0;
}

.subscribe-btn {
  color: #61dafb;
  background-color: rgb(238, 237, 237);
  border: solid;
  border-color: rgb(157, 156, 156);
  border-radius: 0.15rem;
  border-width: 1px;
  width: 90px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.unsubscribe-btn {
  color: #337688;
  background-color: rgb(241, 225, 225);
  border: solid;
  border-color: #fb6161;
  border-radius: 0.15rem;
  border-width: 1px;
  width: 90px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

input {
  padding: 4px;
  border-color: #61dafb;
  border-width: 0 0 1px 0;
  outline: none;
}

input:focus {
  border-color: #fb6161;
}

.data-input {
  display: flex;
  gap: 0.25rem;
}

.main-content {
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  background-color: rgb(255, 255, 255);
  border: solid rgb(21, 21, 21);
  border-width: 1px;
  border-radius: 0 0 0.5rem 0.5rem;
}

.main-content-header {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  position: relative;
}

.main-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.15rem;
  font-size: 14px;
  text-align: left;
}

.main-content-footer {
  position: absolute;
  max-width: 90%;
  align-self: center;
  bottom: 1px;
  left: 5%;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.7rem 0;
  border: solid #a7a6a6;
  border-width: 1px 0 0 0;
}

.next-show {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.7rem 0;
  border: solid #a7a6a6;
  border-width: 1px 0 0 0;
  font-size: 10px;
}

.public-asset-snapshot {
  width: 75%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.15rem;
  padding: 0.5rem 0.25rem;
  background-color: rgb(215, 213, 213);
  border: solid #a7a6a6;
  border-width: 1px;
  border-radius: 0.25rem;
}

.public-asset-snapshot .data {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  gap: 0.15rem;
  padding: 0.5rem 0.75rem 0.5rem 0.25rem;
}

.public-asset-snapshot .description {
  width: 20%;
  text-align: center;
}

.public-asset-snapshot .thumbnail {
  width: 8%;
  text-align: center;
}

.public-asset-snapshot .author {
  font-size: 10px;
}

.public-asset-snapshot a {
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #232323;
  font-weight: 600;
}

.asset-icon-div {
  background-color: white;
  padding: 0.6rem 0.5rem 0.2rem 0.5rem;
  border-radius: 50%;
  border: solid rgb(21, 21, 21);
  border-width: 0.5px;
} */
